import Dispatcher               from '../Dispatcher';
import FluxEventEmitter         from '../FluxEventEmitter';
import Constants                from '../Constants';
import assign                   from 'object-assign';
import {default as ActionTypes} from './../actions/Types/VideoFilterActionTypes';

const filters = {};

const EVENTS = {
	FILTER_CHANGED_EVENT: 'VideoGridFilterChanged',
	FILTER_TOGGLED:       'VideoGridFilterToggled',
};

const VideoFilterStore = assign({}, FluxEventEmitter.prototype, {

	/**
	 * @param {Function} callback
	 */
	addFilterChangeListener: function(callback) {
		this.on(EVENTS.FILTER_CHANGED_EVENT, callback);
	},

	/**
	 * @param {Function} callback
	 */
	removeFilterChangeListener: function(callback) {
		this.removeListener(EVENTS.FILTER_CHANGED_EVENT, callback);
	},

	/**
	 * @param {Function} callback
	 */
	addFilterToggledListener: function(callback) {
		this.on(EVENTS.FILTER_TOGGLED, callback);
	},

	/**
	 * @param {Function} callback
	 */
	removeFilterToggledListener: function(callback) {
		this.removeListener(EVENTS.FILTER_TOGGLED, callback);
	},

	/**
	 * @param {Number} gridId
	 */
	emitChangedEvent: function(gridId) {
		VideoFilterStore.emit(EVENTS.FILTER_CHANGED_EVENT, filters[gridId], gridId);
	},

	/**
	 * @param {Number} gridId
	 * @param {Object} fromServer
	 */
	init: function(gridId, fromServer) {
		filters[gridId] = {...fromServer};
	},

	/**
	 * @returns {Object}
	 */
	getFilter: function(gridId) {
		return typeof filters[gridId] !== 'undefined' ? {...filters[gridId]} : null;
	},

	/**
	 * @param {Number} gridId
	 * @param {Boolean} force
	 */
	toggleExpanded: function(gridId, force = null) {
		filters[gridId].showFilter = null === force ? !filters[gridId].showFilter : force;
		this.emit(EVENTS.FILTER_TOGGLED, gridId, filters[gridId]);
	},

	/**
	 * @param {Number} gridId
	 * @param {String|Number} type
	 */
	setType: function(gridId, type) {
		filters[gridId].type = type;
		filters[gridId].page = 1;
		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number} gridId
	 * @param {String} sort
	 */
	setSort: function(gridId, sort) {
		filters[gridId].sort = sort;
		filters[gridId].page = 1;
		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number} gridId
	 */
	clearTags: function(gridId) {
		filters[gridId].tags = [];

		filters[gridId].page = 1;

		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number} gridId
	 * @param {String} tag
	 */
	toggleTag: function(gridId, tag) {
		if (!Object.hasOwn(filters[gridId], 'tags')) {
			filters[gridId].tags = [];
		}

		const index = filters[gridId].tags.indexOf(tag);

		if (index !== -1) {
			filters[gridId].tags.splice(index, 1);
		} else {
			filters[gridId].tags.push(tag);
		}

		filters[gridId].page = 1;

		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number} gridId
	 */
	toggleIsRecommended: function(gridId) {
		filters[gridId].isRecommended = !filters[gridId].isRecommended;
		filters[gridId].page          = 1;
		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number} gridId
	 */
	toggleIncludeBoughtVideos: function(gridId) {
		filters[gridId].includeBoughtVideos = !filters[gridId].includeBoughtVideos;
		filters[gridId].page                = 1;
		this.emitChangedEvent(gridId);
	},

    /**
     * @param {Number} gridId
     */
    toggleIncludeContestVideos: function(gridId) {
        filters[gridId].includeContestVideos = !filters[gridId].includeContestVideos;
        filters[gridId].page                = 1;
        this.emitChangedEvent(gridId);
    },

	/**
	 * @param {Number} gridId
	 */
	toggleFreeVideosOnly: function(gridId) {
		filters[gridId].freeVideosOnly = !filters[gridId].freeVideosOnly;
		filters[gridId].page           = 1;
		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number} gridId
	 */
	toggleIncludeFreeVideos: function(gridId) {
		filters[gridId].includeFreeVideos = !filters[gridId].includeFreeVideos;
		filters[gridId].page              = 1;
		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number} gridId
	 */
	toggleIncludeOnSaleVideos: function(gridId) {
		filters[gridId].includeOnSaleVideos = !filters[gridId].includeOnSaleVideos;
		filters[gridId].page                = 1;
		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number} gridId
	 */
	toggleIncludeVIPVideos: function(gridId) {
		filters[gridId].includeVIPVideos = !filters[gridId].includeVIPVideos;
		filters[gridId].page             = 1;
		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number} gridId
	 * @param {String} gender
	 */
	toggleGender: function(gridId, gender) {
		if (!Object.hasOwn(filters[gridId], 'actorGenders')) {
			filters[gridId].actorGenders = [];
		}
		const index = filters[gridId].actorGenders.indexOf(gender);

		if (index !== -1) {
			filters[gridId].actorGenders.splice(index, 1);
		} else {
			filters[gridId].actorGenders.push(gender);
		}

		// if no set, set all
		if (filters[gridId].actorGenders.length === 0) {
			// we need to send non-empty array
			filters[gridId].actorGenders = [Constants.ActorGender.NONE];
		}

		filters[gridId].page = 1;

		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number} gridId
	 * @param {Number} from
	 * @param {Number} to
	 */
	setDuration(gridId, from, to) {
		if (!filters[gridId].duration) {
			filters[gridId].duration = {
				from: -1,
				to:   -1,
			};
		}

		let changed = false;

		const newFrom = parseInt(from, 10),
		      newTo   = parseInt(to, 10);

		if (filters[gridId].duration.from !== newFrom) {
			filters[gridId].duration.from = newFrom;
			changed                       = true;
		}

		if (filters[gridId].duration.to !== newTo) {
			filters[gridId].duration.to = newTo;
			changed                     = true;
		}

		if (changed) {
			filters[gridId].page = 1;
			this.emitChangedEvent(gridId);
		}
	},

	/**
	 * @param {Number} gridId
	 * @param {Number} page
	 */
	setPage: function(gridId, page) {
		filters[gridId].page = parseInt(page, 10);
		this.emitChangedEvent(gridId);
	},

	/**
	 * @param {Number|String} gridId
	 * @param {Number} pageSize
	 */
	setPageSize: function(gridId, pageSize) {
		filters[gridId].pageSize = parseInt(pageSize, 10);
		this.emitChangedEvent(gridId);
	},
});

VideoFilterStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.SET_TYPE:
			VideoFilterStore.setType(action.gridId, action.videoType);
			break;

		case ActionTypes.SET_SORT:
			VideoFilterStore.setSort(action.gridId, action.sort);
			break;

		case ActionTypes.TOGGLE_TAG:
			VideoFilterStore.toggleTag(action.gridId, action.tag);
			break;

		case ActionTypes.CLEAR_TAGS:
			VideoFilterStore.clearTags(action.gridId);
			break;

		case ActionTypes.TOGGLE_GENDER:
			VideoFilterStore.toggleGender(action.gridId, action.gender);
			break;

		case ActionTypes.SET_IS_RECOMMENDED:
			VideoFilterStore.toggleIsRecommended(action.gridId);
			break;

		case ActionTypes.SET_INCLUDE_BOUGHT_VIDEOS:
			VideoFilterStore.toggleIncludeBoughtVideos(action.gridId);
			break;

        case ActionTypes.SET_INCLUDE_CONTEST_VIDEOS:
            VideoFilterStore.toggleIncludeContestVideos(action.gridId);
            break;

		case ActionTypes.SET_FREE_VIDEOS_ONLY:
			VideoFilterStore.toggleFreeVideosOnly(action.gridId);
			break;

		case ActionTypes.SET_INCLUDE_FREE_VIDEOS:
			VideoFilterStore.toggleIncludeFreeVideos(action.gridId);
			break;

		case ActionTypes.SET_INCLUDE_ON_SALE_VIDEOS:
			VideoFilterStore.toggleIncludeOnSaleVideos(action.gridId);
			break;

		case ActionTypes.SET_INCLUDE_VIP_VIDEOS:
			VideoFilterStore.toggleIncludeVIPVideos(action.gridId);
			break;

		case ActionTypes.SET_PAGE:
			VideoFilterStore.setPage(action.gridId, action.page);
			break;

		case ActionTypes.SET_PAGE_SIZE:
			VideoFilterStore.setPageSize(action.gridId, action.pageSize);
			break;

		case ActionTypes.SET_DURATION:
			VideoFilterStore.setDuration(action.gridId, action.from, action.to);
			break;

		default:
		// ??
	}
});

export default VideoFilterStore;
