/* globals VXConfig */

import Translations from '../utils/Translations';
import Constants    from '../flux/Constants';
import qs           from 'query-string';
import cookieLite   from '../components/CookieBanner/CookieLite';

// Generate unique key for React child in a list
export const generateKey = (pre, index = "") => {
    pre = pre ? pre.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').replace(/\s/g, "-") : "u-key";
    pre = index ? `${pre}-${index}` : pre;
    return pre;
};

const addClass = function(element, className) {
    if (element && (typeof element === 'object') && className) {
        element.classList.add(className);
    }
};

const removeClass = function(element, className) {
    if (element && (typeof element === 'object') && className) {
        element.classList.remove(className);
    }
};

const getElementWidth = function(element) {
    if (!element) {
        return 0;
    }

    let width = getComputedStyle(element).width;
    width     = width.substring(0, width.length - 2);

    return parseInt(width);
};

const getElementHeight = function(element) {
    if (!element) {
        return 0;
    }

    let height = getComputedStyle(element).height;
    height     = height.substring(0, height.length - 2);

    return parseInt(height);
};

const getOffsetTop = function(element) {
    if (!element) {
        return 0;
    }

    const rect = element.getBoundingClientRect();

    return rect.top + window.scrollY;
};

const getOffsetLeft = function(element) {
    if (!element) {
        return 0;
    }

    const rect = element.getBoundingClientRect();

    return rect.left + window.scrollX;
};

/**
 * returns the position of an given element
 * @param eID string
 * @returns integer
 */
const elmYPosition     = function(eID) {
    const elm = document.getElementById(eID);
    let y     = elm.offsetTop;
    let node  = elm;
    while (node.offsetParent && node.offsetParent !== document.body) {
        node = node.offsetParent;
        y += node.offsetTop;
    }
    return y;
};
/**
 * returns the current position
 * @returns currentpos|0 int
 */
const currentYPosition = function() {
    // Firefox, Chrome, Opera, Safari
    if (self.pageYOffset) {
        return self.pageYOffset;
    }
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop) {
        return document.documentElement.scrollTop;
    }
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) {
        return document.body.scrollTop;
    }
    return 0;
};


/**
 * function to scroll up/down from one element to another
 * @param eID     string
 * @param animate bool
 * @param {Number} offset
 */
const scrollToElem = function(eID, animate, offset = 0) {
    const el = document.getElementById(eID);
    if (!el) {
        return;
    }

    animate         = typeof animate === 'undefined' || animate === true;
    const startPosY = currentYPosition();
    const stopPosY  = elmYPosition(eID) - offset;
    const distance  = stopPosY > startPosY ? stopPosY - startPosY : startPosY - stopPosY;
    if (distance < 100 || !animate) {
        scrollTo(0, stopPosY);
        return;
    }
    let speed = Math.round(distance / 100);
    if (speed >= 20) {
        speed = 20;
    }
    const step = Math.round(distance / 25);
    let leapY  = stopPosY > startPosY ? startPosY + step : startPosY - step;
    let timer  = 0;

    if (stopPosY > startPosY) {
        for (let i = startPosY; i < stopPosY; i += step) {
            setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
            leapY += step;
            if (leapY > stopPosY) {
                leapY = stopPosY;
            }
            timer++;
        }
        return;
    }
    for (let i = startPosY; i > stopPosY; i -= step) {
        setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
        leapY -= step;
        if (leapY < stopPosY) {
            leapY = stopPosY;
        }
        timer++;
    }
};


/**
 * function to scroll up/down from one element to another within a container
 * @param eID     string
 * @param cID     string
 * @param animate bool
 * @param forcedSpeed number
 */
const scrollContainerToElem = function(eID, cID, animate, forcedSpeed) {
    animate = typeof animate === 'undefined' || animate === true;

    const target    = document.getElementById(eID);
    const container = document.getElementById(cID);

    if (!target || !container) {
        return;
    }

    const startPosY = container.scrollTop;
    const stopPosY  = target.offsetTop;
    const distance  = stopPosY > startPosY ? stopPosY - startPosY : startPosY - stopPosY;
    if (distance < 100 || !animate) {
        container.scrollTop = stopPosY;
        return;
    }

    let speed;
    if (typeof forcedSpeed === 'number') {
        speed = forcedSpeed;
    } else {
        speed = Math.round(distance / 100);
        if (speed >= 20) {
            speed = 20;
        }
    }

    const step = Math.round(distance / 25);
    let leapY  = stopPosY > startPosY ? startPosY + step : startPosY - step;
    let timer  = 0;
    if (stopPosY > startPosY) {
        for (let i = startPosY; i < stopPosY; i += step) {
            setTimeout(function(topY) {
                container.scrollTop = topY;
            }.bind(null, leapY), timer * speed);
            leapY += step;
            if (leapY > stopPosY) {
                leapY = stopPosY;
            }
            timer++;
        }
        return;
    }
    for (let i = startPosY; i > stopPosY; i -= step) {
        setTimeout(function(topY) {
            container.scrollTop = topY;
        }.bind(null, leapY), timer * speed);
        leapY -= step;
        if (leapY < stopPosY) {
            leapY = stopPosY;
        }
        timer++;
    }
};


/**
 * @param element
 * @param fn       The function that performs CSS manipulations that should not be transitioned
 *
 * @return string
 */
const disableTransition = function(element, fn) {
    const transitionDisabledClass = 'h-transition-disabled';

    // disable transitions
    element.classList.remove(transitionDisabledClass);

    // perform css changes
    fn();

    // noinspection BadExpressionStatementJS
    element.offsetHeight;

    // enable transitions
    element.classList.remove(transitionDisabledClass);
};

// proudly copy & pasted (and slightly adapted) from http://stackoverflow.com/questions/486896/adding-a-parameter-to-the-url-with-javascript
const getUrlWithinsertedGetParam = function(key, value) {
    key          = encodeURI(key);
    value        = encodeURI(value);
    const search = document.location.search.substr(1);

    let kvp;
    if (search) {
        kvp = search.split('&');
    } else {
        kvp = [];
    }

    let i = kvp.length;
    let x;
    while (i--) {
        x = kvp[i].split('=');

        if (x[0] === key) {
            x[1]   = value;
            kvp[i] = x.join('=');
            break;
        }
    }

    if (i < 0) {
        kvp[kvp.length] = [key, value].join('=');
    }

    return '?' + kvp.join('&');
};

const getUrlParams = function(url) {
    url = url.split('?')[1];
    url = url ? url.split('#')[0] : url;

    const params = {};
    const re     = /[?&]?([^=]+)=([^&]*)/g;
    let tokens;

    while ((tokens = re.exec(url))) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
};

const removeUrlParam = function(key, sourceURL) {
    let rtn           = sourceURL.split("?")[0],
        param,
        params_arr    = [];
    const queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length !== 0) {
            rtn = rtn + "?" + params_arr.join("&");
        }
    }
    return rtn;
};

const updateURLParameter = function(url, param, paramVal) {
    let newAdditionalURL = "";
    let tempArray        = url.toString().split("?");
    const baseURL        = tempArray[0];
    const additionalURL  = tempArray[1];
    let temp             = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].split('=')[0] !== param) {
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }

    const rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
};

const setUrlParameters = function(url, parameters) {
    const baseUrl = url.toString().split('?')[0];
    const keys    = Object.keys(parameters);

    if (keys.length === 0) {
        return baseUrl;
    }

    return baseUrl + '?' + keys.map(key => `${key}=${parameters[key]}`).join('&');
};

const parseQuery = (search = '') => qs.parse(search);

const formatChatPrice = function(number) {
    return formatCurrency(number) + ' / min';
};

/**
 * @param {number} number
 * @param {null|boolean} stripZeros if null, zeros will be stripped or not depending on currency
 * @param {string} currency
 * @param {boolean} whitespace
 * @returns {string}
 */
const formatCurrency = function(number, stripZeros = true, currency = Constants.Currency.VXC, whitespace = true) {
          let formatted = '';

          let fixed = number.toFixed(getCurrencyDecimals(currency)).replace('.', ',');

          if (stripZeros || (shouldStripZeros(currency) && stripZeros !== false)) {
              fixed = fixed.replace(',00', '');
          }

          formatted = fixed;

          if (whitespace) {
              formatted += "\u00a0";
          }

          return formatted + getSymbolForCurrency(currency);
      };


const eurToCoin = (eur) => Math.ceil(eur * 4);

const getSymbolForCurrency = function(currency) {
    let symbol;

    switch (currency) {
        case Constants.Currency.EUR:
            symbol = Constants.CurrencySymbol.EUR;
            break;
        case Constants.Currency.CHF:
            symbol = Constants.CurrencySymbol.CHF;
            break;
        case Constants.Currency.VOC:
            symbol = Constants.CurrencySymbol.VOC;
            break;
        case Constants.Currency.VOU:
            symbol = Constants.CurrencySymbol.VOU;
            break;
        case Constants.Currency.VXC:
            symbol = Constants.CurrencySymbol.VXC;
            break;
        default:
            throw new Error('Unknown currency');
    }

    return symbol;
};

const shouldStripZeros = function(currency) {
    return [Constants.Currency.VOC, Constants.Currency.VOU, Constants.Currency.VXC].includes(currency);
};

const getStaticAmount = (key) => VXConfig.staticAmounts[key];

const formatDateHM = function(date) {
    if (date instanceof Date) {
        return date.getHours() + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    } else {
        return '';
    }
};

const formatDuration = function(duration) {
    let hours   = Math.floor(duration / 3600).toString();
    let minutes = Math.floor((duration % 3600) / 60).toString();
    let seconds = (duration % 60).toString();

    if (hours.length < 2) {
        hours = '0' + hours;
    }
    if (minutes.length < 2) {
        minutes = '0' + minutes;
    }
    if (seconds.length < 2) {
        seconds = '0' + seconds;
    }
    let durationFormated = minutes + ':' + seconds;
    if (hours !== '00') {
        durationFormated = hours + ':' + durationFormated;
    }

    return durationFormated + ' min';
};

const formatLargeNumber = function(number, max = null) {
    const isNegative = number < 0;
    const aboveMax   = !!(max !== null && number > max);
    number           = (aboveMax ? max : Math.abs(number)) + '';

    let numberFormatted = '';
    for (let i = number.length; i > 0; i -= 3) {
        numberFormatted = number.substring(Math.max(0, i - 3), i) + (numberFormatted ? '.' + numberFormatted : numberFormatted);
    }

    if (isNegative) {
        numberFormatted = '-' + numberFormatted;
    } else if (aboveMax) {
        numberFormatted = numberFormatted + '+';
    }

    return numberFormatted;
};

/**
 * Adds leading zeros to a number to get a specific length
 * @param number
 * @param length
 * @returns {string}
 */
const formatNumberToLength = function(number, length) {
    return '0'.repeat(Math.max(length - number.toString().length, 0)) + number;
};

const formatMessagePrice = function(number) {
    return number > 0.0 ? formatCurrency(number) : Translations.get('ChatButtonFree');
};

/**
 * @param text
 * @param replaceArgs Object containing all arguments to replace
 *
 * @return string
 */
const replaceTranslationMarkers = function(text, replaceArgs) {
    return replaceArgs ? text.replace(/(:\w+)/g, function(m, key) {
        return Object.hasOwn(replaceArgs, key) ? replaceArgs[key] : key;
    }) : text;
};

const isMobileRequest = function() {
    return document.documentElement.classList.contains('mobile');
};

const isTouchDevice = function() {
    const selector = document.documentElement;
    return selector.classList.contains('mobile') || selector.classList.contains('tablet');
};

/**
 * Gets a cookie
 * @param cname
 * @returns {*}
 */
const getCookie = (cname) => cookieLite(cname);

/**
 * @param args
 * @param {String} action
 * @param {String} event
 * @param {String} [eventCategory]
 * @param {String} [eventAction]
 * @param {String} [eventLabel]
 * @param {String} [eventValue]
 * @param {String} [fieldsObject]
 *
 * @example gaHelper('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
 * @link https://developers.google.com/analytics/devguides/collection/analyticsjs/events
 */
const gaHelper = (...args) => {
    if (typeof window.ga === 'function') {
        window.ga.apply(null, args);
    }
};

const gtagHelper = (...args) => {
    if (typeof window.gtag === 'function') {
        window.gtag.apply(null, args);
    }
};

function getLocalItemCategory(key) {
    if ([Constants.LocalItemNames.GLOBAL_SEARCH_REQUESTS].includes(key)) {
        return Constants.CookieCategories.FUNCTIONAL;
    }

    return Constants.CookieCategories.NECESSARY;
}

/**
 *
 * @param cname Cookie name
 * @returns {string}
 */
function getCookieCategory(cname) {
    if ([].includes(cname)) {
        return Constants.CookieCategories.FUNCTIONAL;
    }

    return Constants.CookieCategories.NECESSARY;
}

/**
 *
 * @param {string} category Cookie category
 * @returns {boolean}
 */
function isCookieCategoryEnabled(category) {
    if (!window.cookieconsent) {
        return true;
    }

    return window.cookieconsent.allowedCategory(category) || category === Constants.CookieCategories.NECESSARY;
}

function openCookieConsent() {
    if (!window.cookieconsent) {
        return;
    }

    window.cookieconsent.showSettings();
}

/**
 * Set a cookie
 * @param cname
 * @param cvalue
 * @param exdays
 * @param path
 * @param domain
 * @param secure
 * @param sameSiteValue
 */
function setCookie(cname, cvalue, exdays, path = '/', domain = '', secure = false, sameSiteValue = '') {
    if (!window.cookieconsent || isCookieEnabled(cname)) {
        cookieLite(cname, cvalue, (exdays * 24 * 60 * 60), path, domain, secure, sameSiteValue);
    }
}

function isCookieEnabled(cname) {
    return isCookieCategoryEnabled(getCookieCategory(cname));
}

function isLocalItemEnabled(cname) {
    return isCookieCategoryEnabled(getLocalItemCategory(cname));
}

/**
 *
 * @param {string} url
 * @param {object} params
 * @returns {string}
 */
function urlWithQuery(url, params) {
    const query = Object.keys(params)
            .filter(k => params[k] !== undefined)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
    url += (url.indexOf('?') === -1 ? '?' : '&') + query;
    return url;
}

/**
 *
 * @param {string} route
 * @param {object} payload
 * @param {string} method
 * @param {boolean} setCredentials
 * @param {object} init
 * @param {boolean} asBlob
 */
function doFetch(route, payload, method, setCredentials = false, init = {}, asBlob = false, useAuthentication = true) {
    const defaultInit = {
        method:  method,
        headers: {},
    };

    if (useAuthentication && VXConfig.accessToken && VXConfig.accessToken.length > 0) {
        Object.assign(
                defaultInit.headers,
                {
                    'Authorization': VXConfig.accessToken,
                }
        );
    }

    if (!asBlob) {
        Object.assign(
                defaultInit.headers,
                {
                    'Content-Type': 'application/json',
                }
        );
    }

    const mergedInit = mergeDeep(defaultInit, init);

    if (setCredentials) {
        mergedInit.credentials = 'include';
    } else {
        mergedInit.credentials = 'omit';
    }

    if (method !== Constants.HttpMethods.GET && method !== Constants.HttpMethods.HEAD) {
        mergedInit.body = JSON.stringify(payload);
    } else if (method === Constants.HttpMethods.GET && payload) {
        route = urlWithQuery(route, payload);
    }
    return fetch(route, mergedInit).then(function(response) {
        if (response.ok) {
            return asBlob ? response.blob() : response.json();
        }
        throw new Error();
    });
}

function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 *
 * @param   {object} target
 * @param   {...object} sources
 * @returns {object}
 */
function mergeDeep(target, ...sources) {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (Object.hasOwn(source, key)) {
                if (isObject(source[key])) {
                    if (!target[key]) {
                        target[key] = {};
                    }
                    mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, {[key]: source[key]});
                }
            }
        }
    }
    return mergeDeep(target, ...sources);
}

class scroller {
    constructor() {
        this.count = 0;
    }

    rdy() {
        return this.count === 0;
    }

    /**
     *
     * @param eID
     * @param cID
     * @param animate
     * @param callback onScrollEnd
     * @param forcedSpeed
     */
    scrollContainerToElem(eID, cID, animate, callback = function() {
    }, forcedSpeed) {

        if (!this.rdy()) {
            return;
        }

        animate = typeof animate === 'undefined' || animate === true;

        const target    = document.getElementById(eID);
        const container = document.getElementById(cID);
        const startPosY = container.scrollTop;
        const stopPosY  = target.offsetTop;
        const distance  = stopPosY > startPosY ? stopPosY - startPosY : startPosY - stopPosY;
        if (distance < 100 || !animate) {
            container.scrollTop = stopPosY;
            callback();
            return;
        }

        let speed;
        if (typeof forcedSpeed === 'number') {
            speed = forcedSpeed;
        } else {
            speed = Math.round(distance / 100);
            if (speed >= 20) {
                speed = 20;
            }
        }

        const step = Math.round(distance / 25);
        let leapY  = stopPosY > startPosY ? startPosY + step : startPosY - step;
        let timer  = 0;
        if (stopPosY > startPosY) {
            for (let i = startPosY; i < stopPosY; i += step) {
                this.count++;
                setTimeout(function(topY) {
                    container.scrollTop = topY;
                    this.count--;
                    if (this.count === 0) {
                        callback();
                    }
                }.bind(this, leapY), timer * speed);
                leapY += step;
                if (leapY > stopPosY) {
                    leapY = stopPosY;
                }
                timer++;
            }
            return;
        }
        for (let i = startPosY; i > stopPosY; i -= step) {
            this.count++;
            setTimeout(function(topY) {
                container.scrollTop = topY;
                this.count--;
                if (this.count === 0) {
                    callback();
                }
            }.bind(this, leapY), timer * speed);
            leapY -= step;
            if (leapY < stopPosY) {
                leapY = stopPosY;
            }
            timer++;
        }
    }

    scrollUp(element, px, animate = false, callback, speed = 20) {
        if (element && this.rdy()) {
            if (!animate) {
                const current = element.scrollTop;
                if (current < px) {
                    element.scrollTop = 0;
                } else {
                    element.scrollTop = current - px;
                }
                callback();
            } else {
                const startPosY = element.scrollTop;
                const stopPosY  = startPosY - px;
                const step      = Math.round(px / 25);
                let leapY       = startPosY - step;

                let timer = 0;
                for (let i = startPosY; i > stopPosY; i -= step) {
                    this.count++;
                    setTimeout(function(topY) {
                        element.scrollTop = topY;
                        this.count--;
                        if (this.count === 0) {
                            callback();
                        }
                    }.bind(this, leapY), timer * speed);
                    leapY -= step;
                    if (leapY < stopPosY) {
                        leapY = stopPosY;
                    }
                    timer++;
                }
            }
        }
    }

    scrollDown(element, px, animate = false, callback, speed = 20) {
        if (element && this.rdy()) {
            if (!animate) {
                const current     = element.scrollTop;
                element.scrollTop = current + px;
                callback();
            } else {
                const startPosY = element.scrollTop;
                const stopPosY  = startPosY + px;
                const step      = Math.round(px / 25);
                let leapY       = startPosY + step;

                let timer = 0;
                for (let i = startPosY; i < stopPosY; i += step) {
                    this.count++;
                    setTimeout(function(topY) {
                        element.scrollTop = topY;
                        this.count--;
                        if (this.count === 0) {
                            callback();
                        }
                    }.bind(this, leapY), timer * speed);
                    leapY += step;
                    if (leapY > stopPosY) {
                        leapY = stopPosY;
                    }
                    timer++;
                }
            }
        }
    }

}

/**
 *
 * @param {string} value
 * @returns {number}
 */
function generateHash(value) {
    let hash = 0, i, chr;
    if (value.length === 0) {
        return hash;
    }
    for (i = 0; i < value.length; i++) {
        chr  = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

/**
 *
 * @param string
 * @returns {string}
 */
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 *
 * @param string
 * @returns {string}
 */
function lowercaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

/**
 * @param {Function} callback
 * @param {Number} limit
 * @param {Boolean} trailing
 * @return {function()}
 */
const throttle = function(callback, limit, trailing = false) {
    let wait  = false;
    let after = false;

    return () => {
        if (!wait) {
            callback.call();
            wait = true;
            setTimeout(() => {
                wait = false;
                if (trailing && after) {
                    callback.call();
                    after = false;
                }
            }, limit);
        } else if (trailing) {
            after = true;
        }
    };
};

/**
 * @return {boolean}
 */
let _supportsPassive        = null;
const supportsPassiveEvents = function() {
    // check cache first
    if (_supportsPassive !== null) {
        return _supportsPassive;
    }

    /* eslint-disable getter-return */
    let passive   = false;
    const options = {
        get passive() {
            passive = true;
        },
    };
    /* eslint-enable getter-return */

    try {
        document.addEventListener('test', null, options);
        document.removeEventListener('test', null, options);
    } catch (e) {
        // do nothing
    }

    _supportsPassive = passive; // set cache

    return passive;
};

/**
 * Checks if the callbacks provide the correct dataset
 * Returns the dataset or null if the dataset is incomplete/missing
 *
 * @param {Function} getItems
 * @param {Function} getTotalCount
 * @param {Number} count
 * @param {Number} offset
 * @returns {null|[]}
 */
const getCompleteDataset = function(getItems, getTotalCount, count, offset) {
    const allItems   = getItems();
    const totalCount = getTotalCount();
    let items        = [];

    if (allItems) {
        for (let i = offset; i < Math.min(count + offset, totalCount); i++) {
            const item = allItems[i];

            if (!item) {
                // return null if item is missing
                return null;
            }

            items[i - offset] = item;
        }
    } else {
        items = null;
    }

    return items;
};

const addPreventScrolling = () => {
    if (document.querySelector('.prevent-scrolling') === null) {
        document.getElementById('mobile-content-wrapper') && document.getElementById('mobile-content-wrapper').classList.add('mobile-content-wrapper--fixed');
        document.querySelector('body').classList.add('prevent-scrolling');
        if (document.querySelector('html.mobile') !== null && window.Flux.Browser.isSafariMobile()) {
            document.querySelector('html.mobile').classList.add('prevent-scrolling'); // ios safari fix
        }
    }
};

const removePreventScrolling = () => {
    if (document.querySelector('.prevent-scrolling') !== null) {
        document.getElementById('mobile-content-wrapper') && document.getElementById('mobile-content-wrapper').classList.remove('mobile-content-wrapper--fixed');
        document.querySelector('body').classList.remove('prevent-scrolling');
        if (document.querySelector('html.mobile') !== null) {
            document.querySelector('html.mobile').classList.remove('prevent-scrolling');
        }
    }
};


const getUrlParam = function(param) {
    const queryString = window.location.search;
    const urlParams   = new URLSearchParams(queryString);
    return urlParams.get(param);
};

const shouldShowSexoleModal = function() {
    return !window.Flux.Guest.isLoggedIn()
            && !window.Flux.Guest.isAnonymousVoicecall()
            && window.Flux.Browser.isSexole()
            && !window.Flux.Browser.getLocalItem('sexoleLogin')
            && (window.location.hostname === 'sexole.com' || window.location.hostname === 'www.sexole.com' || window.location.hostname === 'm.sexole.com');
};

const shouldShowEsAVSModal = function() {
    return !getCookie(Constants.CookieNames.FORCED_AVS) && VXConfig.showEsAVSModal;
};

const shouldShowExitIntent = function() {
    const incentive = window.Flux.Conversion.getExitIntentExtendedIncentive();
    return incentive?.type === Constants.ConversionIncentiveTypes.EXIT_INTENT_VIDEO_BUY && !window.Flux.Conversion.isIncentiveBlocked(incentive);
};

const shouldShowVipExitIntent = function() {
    const incentive = window.Flux.Conversion.getExitIntentLogoutIncentive();
    return incentive?.type === Constants.ConversionIncentiveTypes.EXIT_INTENT_VIP && !window.Flux.Conversion.isIncentiveBlocked(incentive);
};

/**
 * Checks if an API-relevant cookie exists
 * @returns {boolean}
 */
const existsApiCookie = () => Boolean(getCookie(Constants.CookieNames.FORCED_AVS));

/**
 * A function that takes an array and removes any duplicate objects based on their id property.
 *
 * @param {Array} array - The array to remove duplicates from.
 * @return {Array} The array with duplicate objects removed.
 */
function arrayUniqueById(array) {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i].id === a[j].id) {
                a.splice(j--, 1);
            }
        }
    }

    return a;
}

/**
 * Concatenates two arrays, removes duplicates based on a unique function,
 * and returns a new array with a maximum length.
 *
 * @param {Array} arrayOrig - The original array.
 * @param {Array} arrayConcat - The array to be concatenated.
 * @param {number} maxLen - The maximum length of the resulting array.
 * @param {function} uniqueFn - The function used to remove duplicates.
 * @return {Array} - The resulting array with a maximum length.
 */
function uniqueConcat(arrayOrig, arrayConcat, maxLen, uniqueFn = arrayUniqueById) {
    return uniqueFn(arrayOrig.concat(arrayConcat)).slice(0, maxLen);
}

function isStartPage() {
    return window.location.pathname === '/' || window.location.pathname === '/' + VXConfig.language + '/';
}

function scrollToParam() {
    const queryString = window.location.search;
    const urlParams   = new URLSearchParams(queryString);
    const scrollTo    = urlParams.get('scrollTo');

    if (scrollTo) {
        setTimeout(() => {
            const elem = document.getElementById(scrollTo);
            window.scrollTo({top: elem.offsetTop});
        }, 0);
    }
}

function getCurrencySymbol() {
    return Constants.CurrencySymbol.VXC;
}

function getCurrencyIcon() {
    return "-icon-vxcoin";
}

function getCurrencyDecimals(currency = null) {
    currency = currency || Constants.Currency.VXC;
    return shouldStripZeros(currency) ? 0 : 2;
}

function convertDateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}

// sort component blocks
const sortAndFilterBlockConfigs = (blockConfigs) => {
    return blockConfigs
            .slice() // Create a shallow copy to avoid mutating the original array
            .sort((a, b) => {
                if (a.show === b.show) {
                    return 0; // Maintain original order for equal values
                }
                if (a.show === 0) {
                    return 1; // Push items with show: 0 to the end
                }
                if (b.show === 0) {
                    return -1; // Push items with show: 0 to the end
                }
                return a.show - b.show; // Sort in ascending order
            })
            .filter(config => config.show !== 0); // Remove items with show: 0
};

export {
    addClass,
    currentYPosition,
    removeClass,
    scrollToElem,
    scrollContainerToElem,
    disableTransition,
    doFetch,
    getUrlWithinsertedGetParam,
    getUrlParams,
    getUrlParam,
    updateURLParameter,
    removeUrlParam,
    setUrlParameters,
    formatChatPrice,
    formatCurrency,
    formatDuration,
    formatLargeNumber,
    formatMessagePrice,
    formatNumberToLength,
    replaceTranslationMarkers,
    isMobileRequest,
    isTouchDevice,
    formatDateHM,
    getCookie,
    setCookie,
    existsApiCookie,
    gaHelper,
    gtagHelper,
    urlWithQuery,
    mergeDeep,
    scroller,
    parseQuery,
    generateHash,
    throttle,
    supportsPassiveEvents,
    shouldStripZeros,
    getSymbolForCurrency,
    capitalizeFirstLetter,
    lowercaseFirstLetter,
    getCompleteDataset,
    isLocalItemEnabled,
    isCookieCategoryEnabled,
    openCookieConsent,
    addPreventScrolling,
    removePreventScrolling,
    getElementHeight,
    getElementWidth,
    getOffsetTop,
    getOffsetLeft,
    shouldShowSexoleModal,
    uniqueConcat,
    arrayUniqueById,
    isStartPage,
    scrollToParam,
    shouldShowEsAVSModal,
    shouldShowExitIntent,
    shouldShowVipExitIntent,
    getCurrencySymbol,
    getCurrencyIcon,
    getStaticAmount,
    convertDateToUTC,
    eurToCoin,
    sortAndFilterBlockConfigs,
};
